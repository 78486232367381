import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5585c93e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex p-cell gap-cell border-b border-warm" }
const _hoisted_2 = {
  class: "flex items-center",
  style: { 'max-width': '260px' }
}
const _hoisted_3 = { class: "page mention my-mention ellipsis flex-1" }
const _hoisted_4 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        icon: ['fas', 'grip-dots-vertical'],
        class: "icon-base clickable",
        onClick: _ctx.openWorkspaceList
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", {
      class: "text-base flex items-center justify-center my-mention",
      style: _normalizeStyle({ 'min-width': '23.39px', width: '23.39px', height: '23.39px' })
    }, _toDisplayString(_ctx._workspaceIcon), 5),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx._workspaceName), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Icon, {
        icon: ['fal', 'cog'],
        class: "icon-base clickable",
        onClick: _ctx.openDisplay
      }, null, 8, ["onClick"])
    ])
  ]))
}