
import { defineComponent } from "vue";
import store from "@/store/index";
import {
  ContextMenu,
  WorkspaceListContextMenu,
  WorkspaceHelper,
} from "@/store/util/types";

export default defineComponent({
  name: "WorkspaceHeading",
  computed: {
    _workspaceName: function (): string {
      return store.getters["workspace/getName"];
    },
    _workspaceIcon: function (): string {
      return store.getters["workspace/getIcon"];
    },
  },
  methods: {
    openDisplay: function () {
      store.dispatch("util/pushHelper", new WorkspaceHelper());
    },
    openWorkspaceList: function (event: MouseEvent) {
      store.dispatch(
        "util/pushMenu",
        new WorkspaceListContextMenu(ContextMenu.getLocation(event))
      );
    },
  },
});
