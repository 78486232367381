
import { defineComponent } from "vue";
import store from "@/store/index";
import { TeamHelper } from "@/store/util/types";
import { Page } from "@/store/pages/types";
import WorkspaceHeading from "./WorkspaceHeading.vue";
import PageItem from "./PageItem.vue";

export default defineComponent({
  name: "Navbar",
  components: {
    WorkspaceHeading,
    PageItem,
  },
  watch: {
    _navbarWidth: function () {
      console.log("navbar width is updating... ###################");
    },
    _pages: function (newPages, oldPages) {
      console.log(newPages, oldPages);
    },
  },
  computed: {
    _pages: function (): Array<string> {
      return store.getters["workspace/getPages"];
    },
    _navbarWidth: function (): string {
      return store.getters["user/getSetting"]("navbar-width") + "px";
    },
    _hideNavbar: function (): boolean {
      return store.getters["workspace/hideNavbar"];
    },
  },
  methods: {
    test: function () {
      console.log(store.getters["workspace/getPages"]);
    },
    openTeamHelper: function () {
      store.dispatch("util/pushHelper", new TeamHelper());
    },
    createPage: function () {
      const page = Page.create("", "", "New Page", "");
      store.dispatch("pages/create", page).then((page) => {
        console.log(page);
        this.$router.push({
          name: "Page",
          params: {
            workspaceId: this.$route.params.workspaceId,
            pageId: page.id,
          },
        });
      });
    },
  },
});
