import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AuthGuard = _resolveComponent("AuthGuard")!

  return (_openBlock(), _createBlock(_component_AuthGuard, {
    workspace: true,
    onValid: _ctx.handleValid,
    id: "workspace"
  }, {
    default: _withCtx(() => [
      (_ctx.valid)
        ? (_openBlock(), _createBlock(_component_Navbar, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.valid)
        ? (_openBlock(), _createBlock(_component_router_view, {
            key: 1,
            class: "flex-grow"
          }))
        : _createCommentVNode("", true),
      (_ctx._helper)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "fixed bg-cool",
            style: {
        height: '100vh',
        width: '100vw',
        left: '0',
        top: '0',
        'z-index': '5',
      },
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeHelper && _ctx.closeHelper(...args)))
          }))
        : _createCommentVNode("", true),
      (_ctx._helper)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "fixed bg-paper shadow-base rounded-12 px-34 py-20 overflow-auto scrollbar-sm",
            style: _normalizeStyle({
        height: 'calc(100vh - 40px)',
        width: '36vw',
        'min-width': '500px',
        right: '34px',
        top: '20px',
        'z-index': '5',
      })
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx._helper.component), {
              helper: _ctx._helper,
              class: "",
              style: { 'min-height': '100%' }
            }, null, 8, ["helper"]))
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onValid"]))
}