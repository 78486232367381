
import { defineComponent, nextTick } from "vue";
import store from "@/store/index";
import Navbar from "@/components/layout/navbar/Navbar.vue";
import AuthGuard from "./AuthGuard.vue";
import { User } from "@/store/workspace/types";
import { Helper } from "@/store/util/types";

enum ErrorCode {
  None,
  CantFindPage,
  NotLoggedIn,
  AccessDenied,
  CantFindWorkspace,
}

export default defineComponent({
  name: "Workspace",
  components: {
    AuthGuard,
    Navbar,
  },
  data: function () {
    return {
      valid: false,
      ErrorCode: ErrorCode,
      error: ErrorCode.None,
      loadedWorkspace: false,
    };
  },
  beforeRouteUpdate: function (to, from, next) {
    if (to.params.workspaceId != from.params.workspaceId) {
      this.valid = false;
    }
    next();
  },
  computed: {
    _loggingIn: function (): boolean {
      return store.getters["user/loggingInState"];
    },
    _navbarWidth: function (): number {
      return store.getters["user/getSetting"]("navbar-width");
    },
    _helper: function (): null | Helper {
      return store.getters["util/getHelper"];
    },
  },
  methods: {
    handleValid: function () {
      this.valid = true;
    },
    closeHelper: function () {
      store.dispatch("util/closeHelper");
    },
  },
});
