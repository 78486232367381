
import { defineComponent } from "vue";
import store from "@/store/index";
import { Page } from "@/store/pages/types";
import { ContextMenu, PageContextMenu } from "@/store/util/types";

export default defineComponent({
  name: "PageItem",
  props: {
    page: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      dropdownOpen: false,
    };
  },
  computed: {
    _page: function (): Page {
      return store.getters["pages/getPage"](this.page);
    },
    _name: function (): string {
      return store.getters["pages/getName"](this.page);
    },
    _icon: function (): string {
      return store.getters["pages/getIcon"](this.page);
    },
    _paddingLeft: function (): string {
      return store.getters["pages/getPath"](this.page).length * 8 + 4 + "px";
    },
    _children: function (): Array<string> {
      return this.dropdownOpen
        ? store.getters["pages/getChildren"](this.page)
        : [];
    },
  },
  methods: {
    openPage: function () {
      this.$router.push({
        name: "Page",
        params: {
          workspaceId: this.$route.params.workspaceId,
          pageId: this.page,
        },
      });
    },
    openMenu: function (event: any) {
      store.dispatch(
        "util/pushMenu",
        new PageContextMenu(ContextMenu.getLocation(event), this.page)
      );
    },
    openDropdown: function () {
      if (this._page.children.size > 0) {
        this.dropdownOpen = !this.dropdownOpen;
      }
    },
  },
});
