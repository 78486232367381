import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-608206ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  key: 0,
  class: "icon-base"
}
const _hoisted_3 = { class: "flex-1 ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_PageItem = _resolveComponent("PageItem", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "mention px-cell py-half-cell gap-mention flex clickable grow-animation",
      style: _normalizeStyle({ 'padding-left': _ctx._paddingLeft }),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPage && _ctx.openPage(...args))),
      onContextmenu: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openMenu && _ctx.openMenu(...args)), ["prevent"]))
    }, [
      _createVNode(_component_Icon, {
        icon: _ctx.dropdownOpen ? ['fal', 'caret-down'] : ['fal', 'caret-right'],
        class: _normalizeClass(["icon-base", {
          'text-charcoal-ghost': _ctx._page.children.size == 0,
          clickable: _ctx._page.children.size > 0,
        }]),
        onClick: _withModifiers(_ctx.openDropdown, ["stop"])
      }, null, 8, ["icon", "class", "onClick"]),
      (_ctx._icon.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx._icon), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx._name), 1),
      _createVNode(_component_Icon, {
        icon: ['fal', 'ellipsis'],
        class: "icon-base clickable opacity-0 menu-btn",
        onClick: _withModifiers(_ctx.openMenu, ["stop"])
      }, null, 8, ["onClick"])
    ], 36),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownOpen ? [..._ctx._page.children] : [], (child) => {
      return (_openBlock(), _createBlock(_component_PageItem, {
        page: child,
        key: child
      }, null, 8, ["page"]))
    }), 128))
  ]))
}